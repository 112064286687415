<template>
  <div class="content flex flex-col gap-2">
    <div>
      <b-button
        variant="primary"
        size="sm"
        @click="addMove()"
        :disabled="loadingCount > 0"
      >
        Add new move
      </b-button>
    </div>

    <table class="table table-google moves">
      <thead>
        <tr>
          <th class="text-nowrap">ID</th>
          <th class="text-nowrap">Move</th>
          <th class="text-nowrap">Type</th>
          <th class="text-nowrap row-fit">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="move in moves" :key="move.Id">
          <td class="row-fit text-right">{{ leftPadZero(move.Id, idTargetLength) }}</td>
          <td>{{ move.Name }}</td>
          <td>{{ capitalize(move.Typ) }}</td>
          <td>
            <div class="flex gap-2">
              <b-button
                variant="primary"
                size="sm"
                @click="editMove(move)"
                :disabled="loadingCount > 0"
              >
                Edit
              </b-button>

              <b-button
                variant="danger"
                size="sm"
                @click="deleteMove(move)"
                :disabled="loadingCount > 0"
              >
                Delete
              </b-button>
            </div>
          </td>
        </tr>

        <tr v-if="moves.length === 0">
          <td colspan="4">
            <span v-if="loadingCount > 0">Loading..</span>
            <span v-else>No moves found.</span>
          </td>
        </tr>
      </tbody>
    </table>

    <move-modal
      v-if="moveModal.showModal"
      :data="moveModal"
      :types="types"
      @saved="fetchMoves()"
    />
  </div>
</template>

<script>
import {
  capitalize,
  getArrayTargetLength,
  leftPadZero,
} from '@/helper';

const MoveModal = () => import('@/components/move/MoveModal.vue');

export default {
  name: 'Moves',
  components: {
    MoveModal,
  },
  computed: {
    idTargetLength() {
      return getArrayTargetLength(this.moves.map((move) => move.Id));
    },
  },
  data() {
    return {
      loadingCount: 0,
      types: [],
      moves: [],
      moveModal: {
        showModal: false,
        moveid: null,
      },
    };
  },
  methods: {
    capitalize(text) {
      return capitalize(text);
    },
    fetchTypes() {
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'sana.move' })
        .send({ method: 'types' })
        .then((res) => {
          this.types = res.body.map((type) => ({
            text: capitalize(type),
            value: type,
          }));
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchMoves() {
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'sana.move' })
        .send({ method: 'moves' })
        .then((res) => {
          this.moves = res.body;
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchAll() {
      this.fetchTypes();
      this.fetchMoves();
    },
    addMove() {
      this.moveModal.moveid = null;
      this.moveModal.showModal = true;
    },
    editMove(move) {
      this.moveModal.moveid = move.Id;
      this.moveModal.showModal = true;
    },
    deleteMove(move) {
      const confirmed = confirm(`Do you really wish to delete move '${move.Name}'?`);
      if (!confirmed) {
        return;
      }
      const params = {
        moveid: move.Id,
      };
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'sana.move' })
        .send({ method: 'delete' })
        .send({ params })
        .then(() => {
          this.moves = this.moves.filter((item) => item.Id !== move.Id);
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    leftPadZero(value, targetLength) {
      return leftPadZero(value, targetLength);
    },
  },
  created() {
    this.fetchAll();
  },
};
</script>

<style lang="scss" scoped>
  .moves {
    max-width: 500px;
  }
</style>
