import Vue from 'vue';

export function getColorLuminance(hex) {
  const rgb = parseInt(hex.slice(0, 6), 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luminance;
}

export function isColorDark(hex) {
  const luminance = getColorLuminance(hex);
  return luminance < 150;
}

export function findNearestColor(hex, colors) {
  // Convert the input hex to an RGB color object
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  const color = { r, g, b };

  // Calculate Delta E for each color in the colors array
  let minDeltaE = Infinity;
  let closestIndex = -1;
  for (let i = 0; i < colors.length; i++) {
    const c = {
      r: parseInt(colors[i].substring(0, 2), 16),
      g: parseInt(colors[i].substring(2, 4), 16),
      b: parseInt(colors[i].substring(4, 6), 16),
    };
    const deltaE = Math.sqrt(
      (c.r - color.r) ** 2 * 0.299
      + (c.g - color.g) ** 2 * 0.587
      + (c.b - color.b) ** 2 * 0.114,
    );
    if (deltaE < minDeltaE) {
      minDeltaE = deltaE;
      closestIndex = i;
    }
  }

  return closestIndex;
}

export function downloadFile(link) {
  const anchor = document.createElement('a');
  anchor.href = link;
  anchor.target = '_blank';
  let download = link.split('/').pop();
  download = download.replaceAll('%20', ' ');
  anchor.download = download;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}

export function roundThree(value) {
  const rounded = Number(value).toLocaleString('cs-CZ', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  });
  return rounded;
}

export function numberToString(number) {
  return Number(number).toLocaleString('cs-CZ');
}

export function stringToNumber(number) {
  return Number(String(number).replaceAll(',', '.').replaceAll(/\s/g, ''));
}

export function formatStandardDate(standardDate) {
  const date = new Date(standardDate);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const formattedDate = `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
  return formattedDate;
}

export function swapArrayIndex(array, index1, index2) {
  const temp = array[index1];
  Vue.set(array, index1, array[index2]);
  Vue.set(array, index2, temp);
}

export function canMoveUpArray(array, index) {
  return index > 0 && index < array.length;
}

export function canMoveDownArray(array, index) {
  return index >= 0 && index < array.length - 1;
}

export function moveUpArray(array, index) {
  if (canMoveUpArray(array, index)) {
    swapArrayIndex(array, index, index - 1);
  }
}

export function moveDownArray(array, index) {
  if (canMoveDownArray(array, index)) {
    swapArrayIndex(array, index, index + 1);
  }
}

export function hasArrayDuplicates(array) {
  return new Set(array).size !== array.length;
}

export function getArrayTargetLength(numbers) {
  if (numbers.length === 0) return 0;
  return Math.max(...numbers).toString().length;
}

export function leftPadZero(value, targetLength) {
  return value.toString().padStart(targetLength, '0');
}

export function capitalize(text) {
  if (!text) return text;
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export const datepickerLocale = {
  formatLocale: {
    firstDayOfWeek: 1,
  },
};
